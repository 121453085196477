import request from "graphql-request";
import React, { useEffect, useState, useRef } from "react";
import "../assets/css/outsourcing.scss";
import Checkboxes from "../components/checkboxes";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";

function OutsourcingPage({ pageContext }) {
  const strapiHost = process.env.GATSBY_API_URL;
  const [loading, setLoading] = useState(true);
  const [button, setButton] = useState({});
  const [phrase, setPhrase] = useState({});
  const [data, setData] = useState({});
  const [technologies, setTechnologies] = useState([]);
  const formRef = useRef();
  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          outsourcing(locale: "${pageContext.intl.language}") {
            pageTitle
            title
            title_section2
            description
            img {
              url
            }
            outsourcing_single {
              description
              number
              title
            }
            form{
              addAnotherWorker
              selectSpecialists
              fillContactData
              selectTechnology
              selectLevel
              nameAndSurname
              formHeader
              email
              workerNo
              companyName
              phone
              subject
              message
            }
          }  
          button(locale: "${pageContext.intl.language}"){
            completeyourteam
            acceptterms
            sendquery
          }
          phrase(locale: "${pageContext.intl.language}") {
            validationTooLong
            validationTooShort
            validationRequired
            validationEmail
            validationAcceptTerms
            AcceptTermsForm
            AcceptTerms2Form
          }
          homepage {
            technologies{
              technology
            }
          }
        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.outsourcing);
      setPhrase(items.phrase);
      setButton(items.button);
      setTechnologies(items.homepage.technologies);
      setLoading(false);
    });
  }, []);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <Layout nav={false} initial={false} contactUs={false}>
            <div className="outsourcing__section1">
              <div className="outsourcing__section1-left">
                <img src={`${strapiHost}${data.img.url}`} />
              </div>
              <div className="outsourcing__section1-right">
                <h1 className="outsourcing__section1-title">{data.title}</h1>
                <p className="outsourcing__section1-description">
                  {data.description}
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    formRef.current.scrollIntoView();
                  }}
                >
                  {button.completeyourteam}
                </button>
              </div>
            </div>
          </Layout>
          <div className="outsourcing__section2">
            <Layout nav={false} initial={false} contactUs={false}>
              <h2 className="outsourcing__section2-title">
                {data.title_section2}
              </h2>
              <div className="outsourcing__section2-content">
                {data.outsourcing_single.map(
                  ({ description, number, title }, key) => (
                    <div className="outsourcing__single" key={key}>
                      <h2 className="outsourcing__single-title">{title}</h2>
                      <p className="outsourcing__single-description">
                        {description}
                      </p>
                      <p className="outsourcing__single-number">{number}</p>
                    </div>
                  )
                )}
              </div>
            </Layout>
          </div>
          <Layout nav={false} initial={false} contactUs={false}>
            <div ref={formRef}>
              <Checkboxes
                texts={data.form}
                buttonsTexts={button}
                simplePhrases={phrase}
                technologies={technologies}
              />
            </div>
          </Layout>
        </ShowProvider>
      )}
    </>
  );
}

export default OutsourcingPage;
