import axios from "axios";
import { Field, Form, Formik } from "formik";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby-plugin-react-intl";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import "../assets/css/checkboxes.scss";
import x from "../images/x.svg";
import ButtonLoading from "./buttonLoading";

function Checkboxes({ texts, buttonsTexts, simplePhrases, technologies }) {
  const [workers, setWorkers] = useState([0]);
  const [sending, setSending] = useState(4);
  const strapiHost = process.env.GATSBY_API_URL;
  useEffect(() => {}, [workers]);

  const min = simplePhrases.validationTooShort;
  const max = simplePhrases.validationTooLong;
  const req = simplePhrases.validationRequired;
  const ema = simplePhrases.validationEmail;
  const formSchema = Yup.object().shape({
    name: Yup.string().min(2, min).max(50, max).required(req),
    company: Yup.string().min(2, min).max(50, max),
    phone: Yup.string(),
    email: Yup.string().email(ema).required(req),
    subject: Yup.string().min(2, min).max(50, max).required(req),
    message: Yup.string().min(2, min).max(50, max).required(req),
    terms: Yup.bool().oneOf([true], simplePhrases.validationAcceptTerms),
  });
  return (
    <div className="workers">
      <h1 className="workers__title">{texts.formHeader}</h1>
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          phone: "",
          subject: "",
          message: "",
          workers: [{ easter: "egg" }],
          terms: false,
        }}
        validationSchema={formSchema}
        onSubmit={async (values, { resetForm }) => {
          setSending(1);
          const interval = setTimeout(() => {
            axios
              .post(`${strapiHost}/outsourcing-mails`, {
                name: values.name,
                email: values.email,
                company: values.company,
                phone: values.phone,
                subject: values.subject,
                message: values.message,
                workers: values.workers.map((item) => ({
                  level: item.skill,
                  technologies: item.techs.map((tech) => ({
                    technology: tech,
                  })),
                })),
              })
              .then((e) => {
                resetForm();
                setSending(2);
              })
              .catch((e) => {
                setSending(0);
              });
          }, 2000);
        }}
      >
        {({ values, touched, errors }) => {
          setWorkers(values.workers);
          return (
            <Form className="workers__wrapper">
              <h1 className="workers__hero-title">{texts.selectSpecialists}</h1>
              {workers.map((item, index) => (
                <div key={index} className="workers__worker">
                  <input
                    type="hidden"
                    name={`workers[${index}]['easter']`}
                    value="egg"
                  />
                  <div className="workers__worker__header">
                    <h2 className="workers__worker__title">
                      {texts.workerNo} #{index + 1}
                    </h2>
                    {values.workers.length > 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          setWorkers((e) => {
                            const a = e.filter((item, i) => index !== i);
                            return a;
                          });
                          values.workers.splice(index, 1);
                        }}
                        className="workers__worker__remove-button"
                      >
                        <img src={x} />
                      </button>
                    )}
                  </div>
                  <div className="workers__worker__wrapper">
                    <h3 className="workers__worker__subtitle">
                      {texts.selectTechnology}
                    </h3>
                    <div
                      role="group"
                      aria-labelledby="checkbox-group"
                      className="workers__worker__input-container"
                    >
                      {[
                        ...technologies.map(({ technology }) => {
                          return technology;
                        }),
                        ...[
                          "Node JS",
                          "Wordpress",
                          "Prestashop",
                          "UX/UI",
                          "Project Manager",
                        ],
                      ].map((item, i) => (
                        <label
                          className="workers__worker__checkbox-container"
                          key={i}
                          htmlFor={`workers[${index}][${i}]['techs']`}
                        >
                          <Field
                            type="checkbox"
                            name={`workers[${index}]['techs']`}
                            value={`${item}`}
                            id={`workers[${index}][${i}]['techs']`}
                          />
                          <div className="workers__worker__checkbox" />
                          <span style={{ width: "100px" }}>{item}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="workers__worker__wrapper">
                    <h3 className="workers__worker__subtitle">
                      {texts.selectLevel}
                    </h3>

                    <div
                      role="group"
                      aria-labelledby="checkbox-group"
                      className="workers__worker__input-container"
                    >
                      {["Junior", "Medium", "Senior"].map((item, i) => (
                        <label
                          className="workers__worker__radio-container"
                          key={i}
                        >
                          <Field
                            type="radio"
                            name={`workers[${index}]['skill']`}
                            value={`${item}`}
                          />
                          <div className="workers__worker__radio" />
                          <span style={{ width: "100px" }}>{item}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              <button
                type="button"
                className=" btn btn-xl btn-quartz"
                onClick={() => {
                  values.workers.push({ easter: "egg" });
                  setWorkers((e) => {
                    const a = [...e];
                    a.push({ easter: "egg" });
                    return a;
                  });
                }}
              >
                {texts.addAnotherWorker}
              </button>
              <div className="workers__contact">
                <h1 className="workers__hero-title">{texts.fillContactData}</h1>
                <Row>
                  <Col className="workers__contact__input-container">
                    <label htmlFor="name">{texts.nameAndSurname}</label>
                    <Field
                      id="name"
                      name="name"
                      className="workers__contact__input-container__input"
                    />
                    <p className="workers__contact__input-container__validation">
                      {errors.name && touched.name && <>{errors.name}</>}
                    </p>
                  </Col>
                  <Col className="workers__contact__input-container">
                    <label htmlFor="email">Email</label>
                    <Field
                      id="email"
                      name="email"
                      className="workers__contact__input-container__input"
                    />
                    <p className="workers__contact__input-container__validation">
                      {errors.email && touched.email && <>{errors.email}</>}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="workers__contact__input-container">
                    <label htmlFor="company">{texts.companyName}</label>
                    <Field
                      id="company"
                      name="company"
                      className="workers__contact__input-container__input"
                    />
                    <p className="workers__contact__input-container__validation">
                      {errors.company && touched.company && (
                        <>{errors.company}</>
                      )}
                    </p>
                  </Col>
                  <Col className="workers__contact__input-container">
                    <label htmlFor="phone">{texts.phone}</label>
                    <Field
                      id="phone"
                      name="phone"
                      className="workers__contact__input-container__input"
                    />
                    <p className="workers__contact__input-container__validation">
                      {errors.phone && touched.phone && <>{errors.phone}</>}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="workers__contact__input-container">
                    <label htmlFor="subject">{texts.subject}</label>
                    <Field
                      id="subject"
                      name="subject"
                      className="workers__contact__input-container__input"
                    />
                    <p className="workers__contact__input-container__validation">
                      {errors.subject && touched.subject && (
                        <>{errors.subject}</>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="workers__contact__input-container">
                    <label htmlFor="message">{texts.message}</label>
                    <Field
                      id="message"
                      name="message"
                      component="textarea"
                      rows="4"
                      className="workers__contact__input-container__input"
                    />
                    <p className="workers__contact__input-container__validation">
                      {errors.message && touched.message && (
                        <>{errors.message}</>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-column gap-2">
                    <label
                      className="workers__worker__checkbox-container justify-content-start mt-3 mb-0"
                      htmlFor="terms"
                    >
                      <Field type="checkbox" name="terms" id="terms" />
                      <div className="workers__worker__checkbox" />
                      {simplePhrases.AcceptTermsForm}
                      {"  "}
                      <Link to="/terms">{simplePhrases.AcceptTerms2Form}</Link>
                    </label>
                    <div className="workers__contact__input-container__validation">
                      {errors.terms && touched.terms && (
                        <>{simplePhrases.validationAcceptTerms}</>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="workers__buttons">
                <ButtonLoading
                  type="submit"
                  className="btn btn-xl btn-primary"
                  title={buttonsTexts.sendquery}
                  state={sending}
                  setState={setSending}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Checkboxes;
